import { useState, useEffect, useRef } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { mobileModel } from "react-device-detect";
import Header from "./Header";
import "./index.css";
import axios from "axios";
import phone from "../../assets/images/phone.png";

import { useLocation, useSearchParams } from "react-router-dom";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

  body {
    font-family: 'Montserrat'
  }
`;

const link = document.createElement("link");
link.rel = "stylesheet";
link.href =
	"https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css";
document.head.appendChild(link);

export default function Button1(props) {
	const [searchParams, setSearchParams] = useSearchParams();
	const [inputValue, setInputValue] = useState("");
	const [number, setNumber] = useState("");
	const [checkNext, setCheckNext] = useState(false);
	const [type, setType] = useState(null);
	const [meta, setMeta] = useState(null);
	const [selectedType, setSelectType] = useState("");
	const location = useLocation();

	const handleButtonClick = async () => {
		try {
			try {
				const excelId = searchParams.get("id");
				await axios.post("https://vb-api-lp.vyld.io/forms/registration", {
					phoneNumber: number,
					deviceType: type,
					meta: meta,
					otp: otp,
					websiteData: location,
					excelId,
				});
			} catch (error) {
				if (error?.response?.data?.message == "Invalid OTP") {
					alert("Invalid OTP, Please try again");
					return;
				} else if (error?.response?.data?.message == "already registered") {
					alert("Already registered");
					return;
				} else {
					alert("Something went wrong, Please try again");
					return;
				}
			}
			if (type == "ANDROID")
				window.location.href =
					"https://play.google.com/store/apps/details?id=com.soulgenix.android&pcampaignid=web_share";
			else if (type == "IOS")
				window.location.href =
					"https://apps.apple.com/app/wyb-the-play-social/id6596755967";

			if (type == "WEB") {
				if (selectedType == "android")
					window.location.href =
						"https://play.google.com/store/apps/details?id=com.soulgenix.android&pcampaignid=web_share";
				else
					window.location.href =
						"https://apps.apple.com/app/wyb-the-play-social/id6596755967";
			}
		} catch (error) {
			console.error(
				"There has been a problem with your fetch operation:",
				error
			);
		}
	};

	const handleInputChange = (event) => {
		setInputValue(event.target.value);
	};

	const getData = async () => {
		try {
			const config = {
				method: "GET",
				credentials: "include",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/x-www-form-urlencoded",
				},
			};

			try {
				const response = await fetch(
					"https://vb-api-lp.vyld.io/forms/checkDeviceType",
					config
				);
				if (!response.ok) {
					throw new Error(`HTTP error! status: ${response.status}`);
				}
				const { data } = await response.json();
				setType(data?.deviceType);
				console.log("deviceType");
				if (data?.deviceType == "IOS") {
					// window.location.href =
					// 	"https://apps.apple.com/app/wyb-the-play-social/id6596755967";
					// setTimeout(() => {
					// 	window.location.href = "https://wyb.social/on-list";
					// }, 1000);
				} else if (data?.deviceType == "ANDROID") {
					// const btn = document.getElementById("clickBtn");
					// btn.click();
					// window.location.href =
					// 	"https://play.google.com/store/apps/details?id=com.soulgenix.android";
					// setTimeout(() => {
					// 	window.location.href = "https://wyb.social/on-list";
					// }, 1000);
				} else {
					// window.location.href = "https://wyb.social/pre-registration";
				}

				let a = data?.meta;
				a = JSON.parse(a);

				a.mobileModel = mobileModel;

				a = JSON.stringify(a);
				setMeta(a);
			} catch (error) {
				console.error("Error:", error);
			}
		} catch (err) {
			console.log("Error ", err);
		}
	};

	useEffect(() => {
		getData();

		if (window.grecaptcha) {
			window.grecaptcha.ready(() => {
				window.grecaptcha
					.execute("6LfhKigqAAAAAG3IYlcIPW4TBFMYi2c21MzJAX9A", {
						action: "submit",
					})
					.then((token) => {
						setCheckNext(true);
					});
			});
		}
	}, []);

	const [phoneNumber, setPhoneNumber] = useState(Array(10).fill(""));
	const inputRefs = useRef([]);

	const handleChange = (index, value) => {
		if (value.match(/^[0-9]$/)) {
			const newPhoneNumber = [...phoneNumber];
			newPhoneNumber[index] = value;
			setPhoneNumber(newPhoneNumber);

			if (index < phoneNumber.length - 1) {
				inputRefs.current[index + 1].focus();
			}
		}
	};

	const handleKeyDown = (index, event) => {
		if (event.key === "Backspace") {
			if (index > 0) {
				inputRefs.current[index - 1].focus();
			}
			const newPhoneNumber = [...phoneNumber];
			newPhoneNumber[index] = "";
			setPhoneNumber(newPhoneNumber);
		} else if (event.key === "ArrowRight") {
			if (index < phoneNumber.length - 1) {
				inputRefs.current[index + 1].focus();
			}
		} else if (event.key === "ArrowLeft") {
			if (index > 0) {
				inputRefs.current[index - 1].focus();
			}
		}
	};

	const handleFocus = (index) => {
		inputRefs.current[index].setSelectionRange(0, 1);
	};

	useEffect(() => {
		if (inputRefs.current[0]) {
			inputRefs.current[0].focus();
		}
	}, []);

	const buttonRef = useRef(null);

	useEffect(() => {
		if (buttonRef.current) {
			buttonRef.current.style.setProperty("background", "#C6FF00", "important");
		}
	}, []);

	const inputRef = useRef(null);
	const inputOtpRef = useRef(null);

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, [inputRef]);

	const [otpCheck, setOtpCheck] = useState(false);
	const [otp, setOtp] = useState("");
	const getOtp = async () => {
		if (type == "WEB" && selectedType == "") {
			alert("Select platform");
			return;
		}
		try {
			await axios.post("https://vb-api-lp.vyld.io/auth/otp", {
				phoneNumber: number.toString(),
			});
			setOtpCheck(true);
			inputOtpRef.current.focus();
		} catch (err) {
			console.log("Error ", err);
		}
	};

	return (
		<div
			style={{
				background: "#FF6EC7",
				backgroundImage: `url(${require("../../assets/images/bg.png")})`,
				backgroundSize: "contain",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "center",
				maxHeight: "100vh",
			}}
		>
			<Header />

			<Container style={{ height: "88vh" }}>
				<GlobalStyle />

				<SubContainer>
					<img src={phone} alt="phone bg" />
					<div
						style={{
							color: "#C6FF00",
							fontSize: "3vh",
							fontWeight: "bolder",
							marginTop: "2vh",
						}}
					>
						drop in your digits
					</div>
					<div
						style={{
							width: "95vw",
							textAlign: "center",
							marginTop: "2vh",
							color: "#FFFFFFB2",
						}}
					>
						pre-register today to get early access, exclusive perks, and more!
					</div>

					{otpCheck ? (
						<div
							style={{
								marginTop: "2vh",
								display: "flex",
								justifyContent: "center",
							}}
						>
							<input
								type="number"
								ref={inputOtpRef}
								value={otp}
								maxLength={4}
								onChange={(e) => {
									if (e.target.value.length == 5) return;
									else setOtp(e.target.value);
								}}
								placeholder="0000"
								style={{
									background: "transparent",
									width: "40%",
									textShadow: "2px 3px 0px #161716",
									color: "#0edbe3",
									outline: "none",
									fontSize: "25px",
									color: "white",
									appearance: "textfield",
									WebkitAppearance: "none",
									MozAppearance: "textfield",
									letterSpacing: "8px",
								}}

								// disabled={type == "IOS" ? true : false}
							/>
						</div>
					) : (
						<InputContainer1 style={{ marginTop: "2vh" }}>
							<input
								style={{
									color: "white",
									fontSize: "24px",
									marginRight: "1px",
									width: "18%",
									background: "transparent",
									height: "fit-content",
									fontWeight: "bold",
									textShadow: "2px 3px 0px #161716",
									color: "#0edbe3",
									letterSpacing: "8px",
								}}
								value={"+91"}
								disabled={true}
							/>
							<input
								type="number"
								ref={inputRef}
								value={number}
								maxLength={10}
								onChange={(e) => {
									if (e.target.value.length == 11) return;
									else setNumber(e.target.value);
								}}
								placeholder="0000000000"
								style={{
									background: "transparent",
									width: "80%",
									textShadow: "2px 3px 0px #161716",
									color: "#0edbe3",
									outline: "none",
									fontSize: "25px",
									color: "white",
									appearance: "textfield",
									WebkitAppearance: "none",
									MozAppearance: "textfield",
									letterSpacing: "8px",
								}}

								// disabled={type == "IOS" ? true : false}
							/>
						</InputContainer1>
					)}

					{type == "WEB" && !otpCheck && (
						<RadioContainer style={{ marginTop: "3vh" }}>
							<RadioOption>
								<input
									type="radio"
									id="android"
									name="type"
									value="android"
									checked={selectedType === "android"}
									onChange={(e) => setSelectType(e.target.value)}
									style={{ marginBottom: "12px" }}
								/>
								<label htmlFor="android" style={{ height: "50px" }}>
									<i className="fab fa-google-play"></i> Google Play
								</label>
							</RadioOption>
							<RadioOption>
								<input
									type="radio"
									id="ios"
									name="type"
									value="ios"
									checked={selectedType === "ios"}
									onChange={(e) => setSelectType(e.target.value)}
									style={{ marginBottom: "12px" }}
								/>
								<label htmlFor="ios" style={{ height: "50px" }}>
									<i className="fab fa-apple"></i> Apple Store
								</label>
							</RadioOption>
						</RadioContainer>
					)}

					{/* get otp */}
					{!otpCheck ? (
						<SubmitButton
							style={{ marginTop: "3vh" }}
							onClick={() => {
								if (checkNext) {
									if (number.length != 10) {
										alert("Enter valid number");
										return;
									}
									getOtp();
								} else {
									alert("catcha not verified");
								}
							}}
						>
							Get Otp
						</SubmitButton>
					) : (
						<SubmitCon>
							<SubmitButton onClick={handleButtonClick} ref={buttonRef}>
								{type == "ANDROID" ? (
									<>
										<i className="fab fa-google-play"></i> Submit & Pre Register
									</>
								) : (
									"Submit"
								)}
							</SubmitButton>
						</SubmitCon>
					)}
					{/* text */}
					{selectedType == "android" || type == "ANDROID" ? (
						<div
							style={{
								marginTop: "2vh",
								display: "flex",
								justifyContent: "center",
								color: "#FFFFFFB2",
								width: "95vw",
								textAlign: "center",
							}}
						>
							After entering the OTP,
							<br /> tap on "Pre-Register" on the play store
						</div>
					) : selectedType == "ios" || type == "IOS" ? (
						<div
							style={{
								marginTop: "2vh",
								display: "flex",
								justifyContent: "center",
								color: "#FFFFFFB2",
								width: "95vw",
								textAlign: "center",
							}}
						>
							After entering the OTP,
							<br /> tap on "Get" on the app store
						</div>
					) : (
						<></>
					)}
				</SubContainer>
			</Container>
		</div>
	);
}

const Container = styled.div`
	margin: 0px;
	display: flex;
	flex-direction: column;
	// justify-content: center;
	align-items: center;
	overflow: hidden;
	color: white;
	width: 100vw;
	height: 80vh;
	opacity: 1;
	@media (max-width: 768px) {
		// margin-top: 6vh;
	}
	font-family: "Montserrat";
	// background: "#FF6EC7";
`;

const SubContainer = styled.div`
	margin: 0vh 0vh;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	overflow: hidden;
	color: white;
	font-family: "Montserrat", sans-serif;
`;
const InputContainer1 = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margintop: 2vh;
	width: 20vw;
	@media (max-width: 768px) {
		width: 90vw;
	}
`;
const InputCont = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;
const InputContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 90%;
	color: red;
	margin: 2vh 0vw;
	font-family: "Montserrat", sans-serif;
`;

const CountryCode = styled.div`
	color: white;
	font-size: 25px;
	font-family: "Montserrat", sans-serif;
	margin-right: 5px;
`;

const PhoneInput = styled.input`
	width: 15px;
	height: 30px;
	text-align: center;
	margin-right: 0px;
	font-size: 24px;
	font-weight: bold; /* Added for bold text */
	background: transparent;
	color: white;
	outline: none;
	border: none;
	padding: 0px;

	::placeholder {
		color: rgba(255, 255, 255, 0.5);
	}

	@media (max-width: 768px) {
		width: 20px;
		margin-right: 0px;
		height: auto;
	}

	font-family: "Montserrat", sans-serif;
`;

const RadioContainer = styled.div`
	display: flex;
	margin-bottom: 3vh;
	align-items: center;
	font-family: "Montserrat", sans-serif;
`;

const RadioOption = styled.div`
	display: flex;
	align-items: center;
	text-align: center;
	padding: 6px;
	margin-left: 2vw;
	font-family: "Montserrat", sans-serif;
	margin: 0px 0px 0px 5px;

	label {
		margin-left: 0.5vw;
		display: flex;
		align-items: center;

		i {
			font-size: 15px;
			margin-right: 0.2vw;
		}
	}

	&:first-child {
		margin-left: 0;
	}
`;
const SubmitCon = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 20px;
`;
const SubmitButton = styled.button`
  padding: 8px;
  background: #C6FF00;
  border-radius: 6px;
  font-weight: bold;
  font-size:16px;
  cursor: pointer;
  height:50px;
  width:"100%"
  font-family: 'Montserrat', sans-serif;

  i {
    margin-right: 2vw;
  }
   width: 220px;
  @media (max-width: 768px) {
    width: 220px;
  }
`;
const NumberInput = styled.input`
	background: transparent;
	border: 2px solid pink;
	width: 95%;
	outline: none;
	font-size: 25px;
	color: white;
	appearance: textfield;
	-webkit-appearance: none;
	-moz-appearance: textfield;

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	letter-spacing: 8px;
`;
